import { useEffect } from 'react';
import { AutoSendingDrawer } from '@/components';
import { useToast } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux';
import { useUpdateAutoSendingMutation } from '@/redux/api';
import {
  setUploadedFilePath,
  toggleEditAutoSendingDrawer,
} from '@/redux/features';
import { CreateAutoSendingData } from '@/types';

export const EditAutoSending = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { editAutoSendingDrawerOpen, editableAutoSendingData } = useAppSelector(
    (state) => state.sending,
  );

  const [updateAutoSending, { isSuccess, reset, isLoading }] =
    useUpdateAutoSendingMutation();

  const update = (data: CreateAutoSendingData) => {
    if (editableAutoSendingData) {
      updateAutoSending({
        ...data,
        id: editableAutoSendingData.id,
      });
    }
  };

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(toggleEditAutoSendingDrawer(false));
    dispatch(setUploadedFilePath(''));
    reset();
    toast({
      title: 'Успешно выполнено',
      description: 'Авторассылка успешно отредактирована',
      variant: 'success',
    });
  }, [isSuccess, dispatch, toast, reset]);

  return (
    <AutoSendingDrawer
      isLoading={isLoading}
      action={update}
      open={editAutoSendingDrawerOpen}
      onOpenChange={(bool) => dispatch(toggleEditAutoSendingDrawer(bool))}
      type="edit"
      message={editableAutoSendingData?.message}
      name={editableAutoSendingData?.name}
      delay_days={editableAutoSendingData?.delay_days}
      delay_hours={editableAutoSendingData?.delay_hours}
      delay_minutes={editableAutoSendingData?.delay_minutes}
      group_id={editableAutoSendingData?.group_id}
    />
  );
};
