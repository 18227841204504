import { useState } from 'react';
import Dropzone, { DropEvent, ErrorCode, FileRejection } from 'react-dropzone';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { AxiosProgressEvent } from 'axios';
import { useToast } from '@/hooks';
import { uploadsApi } from '@/lib/uploadsApi';
import { cn, reactDropzoneError } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/redux';
import { setUploadedFilePath } from '@/redux/features';
import { UploadFileResponse } from '@/types';
import './FileUploader.css';

export const FileUploader = () => {
  const [progress, setProgress] = useState(0);
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const uploadedUrl = useAppSelector((state) => state.sending.uploadedFilePath);

  const onDropAccepted = async (files: File[]) => {
    let path = '';
    if (files[0].type.includes('image')) {
      path = 'media/upload/image';
    }
    if (files[0].type.includes('video')) {
      path = 'media/upload/video';
    }
    if (!path) return;

    try {
      const { data } = await uploadsApi.post<UploadFileResponse>(
        path,
        { file: files[0] },
        {
          onUploadProgress: (upload: AxiosProgressEvent) => {
            const { loaded, total } = upload;
            let progress = Math.round((100 * loaded) / (total ? total : 0));
            if (progress) setProgress(progress);
          },
        },
      );

      dispatch(setUploadedFilePath(data.path));
    } catch (error) {
      console.log(error);

      toast({
        title: 'Ошибка',
        description: 'Что-то пошло не так',
        variant: 'destructive',
      });
    }
  };

  const onDropRejected = (fileRejections: FileRejection[], _: DropEvent) => {
    const errorCode = fileRejections[0].errors[0].code;
    const error = reactDropzoneError(errorCode as ErrorCode);
    toast({
      title: 'Ошибка загрузки',
      description: error,
      variant: 'destructive',
    });
  };

  const domain = process.env.REACT_APP_API_URL;

  const style =
    uploadedUrl && !uploadedUrl.includes('.mp4')
      ? {
          backgroundImage: `url(${domain + uploadedUrl})`,
          backgroundSize: 'cover',
        }
      : {};

  return (
    <>
      <Dropzone
        maxFiles={1}
        multiple={false}
        maxSize={1000000 * 80} // 80mb
        accept={{
          'image/jpeg': [],
          'video/mp4': ['.mp4', '.MP4'],
        }}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={cn(
              'relative',
              !uploadedUrl &&
                'border-dashed hover:border-primary border-2 rounded-md',
            )}
          >
            <div
              {...getRootProps({
                className: cn('file-uploader relative'),
              })}
              style={style}
            >
              <input {...getInputProps()} />

              {!uploadedUrl && <BsFileEarmarkArrowDown size={'30px'} />}
              {uploadedUrl && uploadedUrl.includes('.mp4') && (
                <div className=" h-[198px] overflow-hidden">
                  <video src={domain + uploadedUrl}></video>
                </div>
              )}
            </div>
            {uploadedUrl && (
              <span
                className="absolute top-2 right-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setUploadedFilePath(''));
                }}
              >
                <AiOutlineCloseCircle size={20} />
              </span>
            )}
          </div>
        )}
      </Dropzone>
      <div className="h-[10px]">
        {progress !== 0 && progress !== 100 && (
          <progress
            className="progress progress-primary"
            value={progress}
            max="100"
          ></progress>
        )}
      </div>
    </>
  );
};
