import { MobileMenu } from '../MobileMenu/MobileMenu';
import { TopMenu } from '../TopMenu/TopMenu';
import { Button } from '../UI';
import { routing } from '@/configs';
import './Header.css';

export const Header = () => {
  const logoutBtnClickHandler = () => {
    localStorage.removeItem('at');
    window.location.href = routing.signin;
  };

  return (
    <header>
      <div className="header-content">
        <div className="flex items-center gap-5">
          <MobileMenu />
          <div className="naming">TG BOT</div>
          <TopMenu />
        </div>
        <Button size="sm" onClick={logoutBtnClickHandler} variant="ghost">
          Выход
        </Button>
      </div>
    </header>
  );
};
