import React, { FC, useEffect, useRef, useState } from 'react';
import { GroupsSelect } from '../GroupsSelect/GroupsSelect';
import { ru } from 'date-fns/locale';
import { FileUploader } from '@/components';
import {
  Button,
  Calendar,
  InputGroup,
  Label,
  ScrollArea,
  Textarea,
} from '@/components/UI';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/UI/Sheet';
import { useToast } from '@/hooks';
import { cn } from '@/lib/utils';
import { useAppDispatch, useAppSelector } from '@/redux';
import { setUploadedFilePath } from '@/redux/features';
import { CreateSendingData, UpdateSendingData } from '@/types';
import './SendingDrawer.css';

type SendingDrawerProps = {
  isLoading: boolean;
  action: (payload: CreateSendingData) => void;
  open: boolean;
  onOpenChange: (b: boolean) => void;
  trigger?: React.ReactNode;
  type: 'create' | 'edit';
  group_id?: number | null;
  message?: string;
  sending_date?: string;
};

export const SendingDrawer: FC<SendingDrawerProps> = ({
  isLoading,
  action,
  open,
  onOpenChange,
  trigger,
  type,
  group_id,
  message,
  sending_date,
}) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const ref = useRef<HTMLDivElement>(null);

  const { sending_active } = useAppSelector((state) => state.bot);
  const { uploadedFilePath } = useAppSelector((state) => state.sending);

  const [messageVal, setMessageVal] = useState('');
  const [groupId, setGroupId] = useState<number>();

  const [date, setDate] = useState<Date>();
  const [hour, setHour] = useState<number>();
  const [minute, setMinute] = useState<number>();
  const hasDate =
    date !== undefined && hour !== undefined && minute !== undefined;

  const [messageError, setMessageError] = useState('');

  const messageChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setMessageVal(value);
    if (messageError && value) setMessageError('');
  };

  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (type === 'create' && sending_active && !hasDate) {
      return toast({
        title: 'Ошибка',
        description: 'На данный момент уже идет рассылка',
        variant: 'destructive',
      });
    }

    if (type === 'edit' && !hasDate) return;

    if (!messageVal) {
      setMessageError('Введите сообщение');
      return ref.current?.scrollTo({ top: 0 });
    }

    const payload: CreateSendingData | UpdateSendingData = {
      message: messageVal,
    };

    if (hasDate) {
      const d = new Date(date);
      d.setHours(hour, minute, 0, 0);
      payload.sending_date = d.toISOString();
    }

    if (uploadedFilePath) payload.file_path = uploadedFilePath;
    if (groupId) payload.group_id = groupId;

    action(payload);
  };

  const clearSendingDate = () => {
    setDate(undefined);
    setMinute(undefined);
    setHour(undefined);
  };

  useEffect(() => {
    if (open) return;
    clearSendingDate();
    dispatch(setUploadedFilePath(''));
    setMessageVal('');
    setGroupId(undefined);
    setMessageError('');
  }, [open, dispatch]);

  useEffect(() => {
    if (group_id) {
      setGroupId(group_id);
    }
    if (message) setMessageVal(message);
    if (sending_date) {
      const date = new Date(sending_date);
      setDate(date);
      setHour(date.getHours());
      setMinute(date.getMinutes());
    }
  }, [group_id, message, sending_date]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {trigger && (
        <SheetTrigger asChild>
          <span>{trigger}</span>
        </SheetTrigger>
      )}

      <SheetContent
        ref={ref}
        className="w-full md:max-w-[50%] lg:max-w-[40%] xl:max-w-[30%]"
      >
        <SheetHeader>
          <SheetTitle>
            {type === 'create' ? 'Создать рассылку' : 'Редактировать рассылку'}
          </SheetTitle>
        </SheetHeader>

        <form className="mt-5 flex flex-col gap-5" onSubmit={formSubmitHandler}>
          <GroupsSelect
            value={groupId ? groupId.toString() : '0'}
            onValueChange={(val) => {
              if (!Number.isNaN(parseInt(val))) {
                setGroupId(parseInt(val));
              }
            }}
          />

          <InputGroup
            id="message"
            name="Сообщение для рассылки"
            error={messageError}
          >
            <Textarea
              className="min-h-[200px]"
              id="message"
              placeholder="Введите текст"
              value={messageVal}
              onChange={messageChangeHandler}
            />
          </InputGroup>

          <div className="w-full">
            <Label className="flex mb-2" htmlFor="file">
              Изображение или видео
            </Label>
            <FileUploader />
          </div>

          <div>
            <div className="flex mb-2 justify-between gap-3">
              <Label htmlFor="file">Дата и время рассылки</Label>
              {hasDate && (
                <Label
                  className="text-primary cursor-pointer"
                  onClick={clearSendingDate}
                >
                  Сбросить
                </Label>
              )}
              {type === 'edit' && !hasDate && (
                <Label className="text-red-400 cursor-pointer">
                  Укажите дату
                </Label>
              )}
            </div>
            <div className="flex flex-col xs:flex-row gap-5">
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                className="rounded-md border w-fit"
                locale={ru}
                fromDate={new Date()}
              />

              <div className="scrolled-areas">
                <ScrollArea>
                  {new Array(24).fill('').map((_, i) => (
                    <div
                      key={i}
                      className={cn('item', hour === i && 'active')}
                      onClick={() => setHour(i)}
                    >
                      {i < 10 ? `0${i}` : i}
                    </div>
                  ))}
                </ScrollArea>
                <ScrollArea>
                  {new Array(60).fill('').map((_, i) => (
                    <div
                      key={i}
                      className={cn('item', minute === i && 'active')}
                      onClick={() => setMinute(i)}
                    >
                      {i < 10 ? `0${i}` : i}
                    </div>
                  ))}
                </ScrollArea>
              </div>
            </div>
          </div>

          <Button loading={isLoading}>
            {hasDate ? 'Сохранить' : 'Отправить сейчас'}
          </Button>
        </form>
      </SheetContent>
    </Sheet>
  );
};
