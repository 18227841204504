import { AddCommandDialog } from './AddCommandDialog';
import { ConfirmDeleteAction } from '@/components/';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  EmptyData,
  Loader,
} from '@/components/UI/';
import { useDeleteCommandMutation, useGetCommandsQuery } from '@/redux/api';

export const Commands = () => {
  const { data, isLoading } = useGetCommandsQuery();

  const [deleteCommand, { isSuccess }] = useDeleteCommandMutation();

  return (
    <>
      <div className="commands">
        {isLoading ? (
          <Loader />
        ) : data && data.rows.length ? (
          <Accordion type="multiple" className="w-full">
            {data.rows.map((command) => (
              <AccordionItem key={command.command} value={command.command}>
                <AccordionTrigger>
                  <span className="text-gray-500">
                    <span className="text-foreground">{command.command} </span>
                    {command.description}
                  </span>
                  <ConfirmDeleteAction
                    deleteAction={() => deleteCommand({ id: command.id })}
                    deleteStatus={isSuccess}
                    text="Вы точно хотите удалить команду?"
                    absolute
                  />
                </AccordionTrigger>

                <AccordionContent>{command.text}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <EmptyData text="команд пока нет" />
        )}
      </div>
      <div className="mt-3">
        <AddCommandDialog />
      </div>
    </>
  );
};
