import { FC } from 'react';
import { EditAutoSending } from './EditAutoSending';
import { SendingAccordion } from '@/components';
import { Loader } from '@/components/UI';
import { useAppDispatch } from '@/redux';
import {
  useDeleteAutoSendingMutation,
  useGetAutoSendingsInGroupQuery,
} from '@/redux/api';
import {
  setEditableAutoSendingData,
  setUploadedFilePath,
  toggleEditAutoSendingDrawer,
} from '@/redux/features';
import { AutoSendingData } from '@/types';

type Props = {
  skip: boolean;
  group_id: number | undefined;
};

export const PlannedAutoSending: FC<Props> = ({ skip, group_id }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAutoSendingsInGroupQuery(
    { group_id },
    { skip },
  );

  const [deleteAutoSending, { isSuccess }] = useDeleteAutoSendingMutation();

  const editAction = (data: AutoSendingData) => {
    if (data.file_path) dispatch(setUploadedFilePath(data.file_path));

    const editable = { ...data, file_path: undefined };
    dispatch(setEditableAutoSendingData(editable));

    dispatch(toggleEditAutoSendingDrawer(true));
  };

  if (isLoading) return <Loader />;

  return data?.length ? (
    <div>
      {data.map((item) => (
        <SendingAccordion
          id={item.id}
          key={item.id}
          filePath={item.file_path}
          message={item.message}
          deleteAction={() => deleteAutoSending({ id: item.id })}
          deleteStatus={isSuccess}
          editAction={() => editAction(item)}
          title={
            <div className="flex gap-2">
              <span>{item.name}</span>
              <span className="font-normal">
                через {item.delay_days && ` дней: ${item.delay_days}`}{' '}
                {item.delay_hours && ` часов: ${item.delay_hours}`}{' '}
                {item.delay_minutes && ` минут: ${item.delay_minutes}`}
              </span>
            </div>
          }
        />
      ))}
      <EditAutoSending />
    </div>
  ) : (
    <div>Нет запланированных рассылок</div>
  );
};
