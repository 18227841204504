import { clsx, type ClassValue } from 'clsx';
import { ErrorCode } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const reactDropzoneError = (errorCode: ErrorCode) => {
  switch (errorCode) {
    case ErrorCode.FileInvalidType:
      return 'Неверный формат файла';
    case ErrorCode.FileTooLarge:
      return 'Превышен размер файла';
    case ErrorCode.FileTooSmall:
      return 'Слишком маленький размер файла';
    case ErrorCode.TooManyFiles:
      return 'Слишком много файлов';

    default:
      return 'Невалидный файл';
  }
};
