import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  sending_active: false,
};

const botSlice = createSlice({
  initialState,
  name: 'bot',
  reducers: {
    toggleSendingStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.sending_active = payload;
    },
  },
});
export const botReducer = botSlice.reducer;

export const { toggleSendingStatus } = botSlice.actions;
