import { useEffect, useState } from 'react';
import { GroupFormDialog } from './GroupFormDialog';
import { Button } from '@/components/UI';
import { useToast } from '@/hooks';
import { useAddGroupMutation } from '@/redux/api';
import { ErrorResponse } from '@/types';

export const AddGroup = () => {
  const { toast } = useToast();

  const [open, setOpen] = useState(false);
  const [addGroup, { data, error, reset }] = useAddGroupMutation();

  useEffect(() => {
    if (!data) return;
    setOpen(false);
    reset();
    toast({
      title: 'Успешно выполнено',
      description: 'Группа добавлена',
      variant: 'success',
    });
  }, [data, reset, toast]);

  const errorMessage = error
    ? (error as ErrorResponse)?.data.errors[0]['name']
    : undefined;

  return (
    <GroupFormDialog
      open={open}
      onOpenChange={setOpen}
      action={(name) => addGroup({ name })}
      error={errorMessage}
      type="add"
      trigger={<Button className="w-full sm:w-fit">Добавить группу</Button>}
    />
  );
};
