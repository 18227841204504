import { CreateSending, PlannedSending } from './components';
import { Alert, AlertTitle } from '@/components/UI';
import { useAppSelector } from '@/redux';
import './Sending.css';

export const Sending = () => {
  const { sending_active } = useAppSelector((state) => state.bot);

  return (
    <>
      {sending_active && (
        <Alert className="border-primary  text-primary">
          <AlertTitle>На данный момент идет рассылка!</AlertTitle>
        </Alert>
      )}
      <CreateSending />
      <div className="content mb-10">
        <h1>Запланированные рассылки</h1>
        <PlannedSending />
      </div>
    </>
  );
};
