import { useEffect, useState } from 'react';
import { RiLoader3Fill } from 'react-icons/ri';
import { Outlet } from 'react-router-dom';
import { io } from 'socket.io-client';
import { routing } from '@/configs';
import { useAppDispatch } from '@/redux';
import { sendingAPI, useCheckAuthQuery } from '@/redux/api';
import { toggleSendingStatus } from '@/redux/features';

const wsPath = process.env.REACT_APP_API_URL;

export const AuthProvider = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>();
  const { isSuccess, error } = useCheckAuthQuery();

  useEffect(() => {
    if (!error) return;
    localStorage.removeItem('at');
    window.location.href = routing.signin;
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    setLoading(false);
  }, [isSuccess]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!wsPath || !isSuccess) return;
    const socket = io(wsPath, {
      transportOptions: {
        polling: { extraHeaders: { user_id: 1 } },
      },
    });

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') socket.connect();
    });

    socket.on('connect', () => {
      console.log('connect');
    });

    socket.on('sending_active', (data: { sending_active: boolean }) => {
      dispatch(toggleSendingStatus(data.sending_active));
      dispatch(sendingAPI.util.invalidateTags(['Sending']));
    });

    return () => {
      socket.close();
    };
  }, [isSuccess, dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <RiLoader3Fill className="h-8 w-8 fill-primary animate-spin-loader" />
      </div>
    );
  }

  return <Outlet />;
};
