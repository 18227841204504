import { FC } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { GroupLink } from './GroupLink';
import { ConfirmDeleteAction } from '@/components';
import { Button } from '@/components/UI';
import { routing } from '@/configs';
import { useAppDispatch } from '@/redux';
import { useDeleteGroupMutation } from '@/redux/api';
import { setEditableGroupData, toggleEditDialog } from '@/redux/features';
import { GroupWithUsersCount } from '@/types';

type Props = {
  group: GroupWithUsersCount;
};

export const GroupCard: FC<Props> = ({ group }) => {
  const dispatch = useAppDispatch();
  const [deleteGroup, { isSuccess }] = useDeleteGroupMutation();

  const editIconClickHandler = (id: number, name: string) => {
    dispatch(setEditableGroupData({ id, name }));
    dispatch(toggleEditDialog(true));
  };

  return (
    <div className="group-card">
      <div className="flex justify-between items-center">
        <div>
          Название: <span className="font-medium">{group.name}</span>
        </div>
        <GroupLink groupId={group.id} />
      </div>
      <div>
        ID: <span className="font-medium">{group.id}</span>
      </div>
      <div>
        Пользователей: <span className="font-medium">{group.users}</span>
      </div>

      <div className="flex flex-col gap-3 sm:flex-row mt-5 sm:mt-10 justify-between sm:items-center">
        <Link to={`${routing.auto_sending}/${group.id}`}>
          <Button size="sm">Управлять авторассылкой</Button>
        </Link>
        <div className="flex  gap-2 justify-end  sm:justify-start">
          <span onClick={() => editIconClickHandler(group.id, group.name)}>
            <AiOutlineEdit size={21} className=" cursor-pointer" />
          </span>

          <ConfirmDeleteAction
            deleteStatus={isSuccess}
            deleteAction={() => deleteGroup({ id: group.id })}
            text="Вы точно хотите удалить группу?"
            absolute={false}
          />
        </div>
      </div>
    </div>
  );
};
