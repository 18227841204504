import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CreateAutoSending } from './components/CreateAutoSending';
import { PlannedAutoSending } from './components/PlannedAutoSending';
import { Button } from '@/components/UI';
import { routing } from '@/configs';
import { useGetGroupQuery } from '@/redux/api';
import './AutoSending.css';

export const AutoSending = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [skip, setSkip] = useState(true);
  const [groupId, setGroupId] = useState<number>();

  const { data: groupData, isError } = useGetGroupQuery(
    { id: groupId },
    { skip },
  );

  useEffect(() => {
    const regex = /^\d+$/;
    if (regex.test(params.group_id!)) {
      setGroupId(parseInt(params.group_id!));
      setSkip(false);
    } else {
      navigate(routing.groups);
    }
  }, [params, navigate]);

  useEffect(() => {
    if (!isError) return;
    navigate(routing.groups);
  }, [isError, navigate]);

  return (
    <>
      <div className="content">
        <div className="flex gap-3 mb-5">
          <Link to={routing.groups}>
            <Button className="p-2" variant="outline">
              <ChevronLeft />
            </Button>
          </Link>
          <h1>Группа: {groupData?.name}</h1>
        </div>

        <CreateAutoSending group_id={groupData?.id} />
        <div className="my-10">
          <h1>Список авторассылок</h1>
          <PlannedAutoSending skip={skip} group_id={groupId} />
        </div>
      </div>
    </>
  );
};
