import { createApi } from '@reduxjs/toolkit/dist/query/react';
import customFetch from './customFetch';
import { CreateSendingData, SendingData, UpdateSendingData } from '@/types';

export const sendingAPI = createApi({
  reducerPath: 'sendingAPI',
  baseQuery: customFetch,

  tagTypes: ['Sending'],
  endpoints: (builder) => ({
    createSending: builder.mutation<
      { status: string; sending_date: string },
      CreateSendingData
    >({
      query: (body) => ({
        url: '/sending',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Sending'],
    }),
    updateSending: builder.mutation<
      { status: string; sending_date: string },
      UpdateSendingData
    >({
      query: (body) => ({
        url: '/sending',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Sending'],
    }),
    getPlanned: builder.query<SendingData[], void>({
      query: () => ({
        url: '/sending/planned',
        method: 'GET',
      }),
      providesTags: (_) => ['Sending'],
    }),
    deleteSending: builder.mutation<{ result: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/sending/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sending'],
    }),
  }),
});

export const {
  useCreateSendingMutation,
  useGetPlannedQuery,
  useDeleteSendingMutation,
  useUpdateSendingMutation,
} = sendingAPI;
