import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FileUploader } from '@/components';
import { Button, Input, InputGroup, Label, Textarea } from '@/components/UI';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/UI/Sheet';
import { useAppDispatch, useAppSelector } from '@/redux';
import { setUploadedFilePath } from '@/redux/features';
import { CreateAutoSendingData } from '@/types';
import './AutoSendingDrawer.css';

interface UseFormData
  extends Omit<
    CreateAutoSendingData,
    'delay_days' | 'delay_hours' | 'delay_minutes'
  > {
  delay_days: string;
  delay_hours: string;
  delay_minutes: string;
}

type AutoSendingDrawerProps = {
  isLoading: boolean;
  action: (payload: CreateAutoSendingData) => void;
  open: boolean;
  onOpenChange: (b: boolean) => void;
  trigger?: React.ReactNode;
  type: 'create' | 'edit';
  message?: string;
  name?: string;
  group_id?: number;
  delay_days?: number | null;
  delay_hours?: number | null;
  delay_minutes?: number | null;
};

export const AutoSendingDrawer: FC<AutoSendingDrawerProps> = ({
  open,
  onOpenChange,
  trigger,
  isLoading,
  action,
  group_id,
  type,
  message,
  name,
  delay_days,
  delay_hours,
  delay_minutes,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { uploadedFilePath } = useAppSelector((state) => state.sending);

  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
    setValue,
  } = useForm<UseFormData>();

  const [delayError, setDelayError] = useState(false);

  const formSubmitHandler = (data: UseFormData) => {
    if (!group_id) return;
    const { message, name } = data;

    const payload: CreateAutoSendingData = {
      name,
      message,
      group_id,
    };
    if (uploadedFilePath) payload.file_path = uploadedFilePath;

    const delay_days = parseInt(data.delay_days);
    const delay_hours = parseInt(data.delay_hours);
    const delay_minutes = parseInt(data.delay_minutes);

    if (
      !Boolean(delay_days) &&
      !Boolean(delay_hours) &&
      !Boolean(delay_minutes)
    ) {
      return setDelayError(true);
    }

    if (Boolean(delay_days) && !isNaN(delay_days)) {
      payload.delay_days = delay_days > 0 ? delay_days : undefined;
    }

    if (Boolean(delay_hours) && !isNaN(delay_hours)) {
      payload.delay_hours = delay_hours > 0 ? delay_hours : undefined;
    }

    if (Boolean(delay_minutes) && !isNaN(delay_minutes)) {
      payload.delay_minutes = delay_minutes > 0 ? delay_minutes : undefined;
    }
    action(payload);
  };

  useEffect(() => {
    if (open) return;
    dispatch(setUploadedFilePath(''));
    resetForm();
    setDelayError(false);
  }, [open, dispatch, resetForm]);

  useEffect(() => {
    if (name) setValue('name', name);
    if (message) setValue('message', message);
    if (delay_days) setValue('delay_days', delay_days.toString());
    if (delay_hours) setValue('delay_hours', delay_hours.toString());
    if (delay_minutes) setValue('delay_minutes', delay_minutes.toString());
  }, [message, name, delay_days, delay_hours, delay_minutes, setValue]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {trigger && (
        <SheetTrigger asChild>
          <span>{trigger}</span>
        </SheetTrigger>
      )}

      <SheetContent
        ref={ref}
        className="w-full md:max-w-[50%] lg:max-w-[40%] xl:max-w-[30%]"
      >
        <SheetHeader>
          <SheetTitle>
            {type === 'create'
              ? 'Создать авторассылку'
              : 'Редактировать авторассылку'}
          </SheetTitle>
        </SheetHeader>

        <form className="mt-5 flex flex-col gap-5">
          <InputGroup
            id="name"
            name="Название"
            error={formState.errors.name?.message}
          >
            <Input {...register('name', { required: 'введите название' })} />
          </InputGroup>

          <InputGroup
            id="message"
            name="Сообщение для рассылки"
            error={formState.errors.message?.message}
          >
            <Textarea
              className="min-h-[200px]"
              id="message"
              placeholder="Введите текст"
              {...register('message', {
                required: 'введите сообщение для рассылки',
              })}
            />
          </InputGroup>

          <div className="w-full">
            <Label className="flex mb-2" htmlFor="file">
              Изображение или видео
            </Label>
            <FileUploader />
          </div>

          <div>
            <div className="flex justify-between gap-3">
              <Label>
                Отправить через (дни, часы, минуты) <br /> после попадания в
                группу:
              </Label>
              {delayError && (
                <Label className="text-red-400">Укажите время</Label>
              )}
            </div>
            <div className="mt-2 flex gap-5 border rounded-md p-5">
              <div>
                <Label>дни</Label>
                <Input
                  type="number"
                  {...register('delay_days')}
                  min={0}
                  onChange={() => delayError && setDelayError(false)}
                  className="mt-2 max-w-[100px]"
                />
              </div>
              <div>
                <Label>часы</Label>
                <Input
                  type="number"
                  min={0}
                  {...register('delay_hours')}
                  onChange={() => delayError && setDelayError(false)}
                  className="mt-2 max-w-[100px]"
                />
              </div>
              <div>
                <Label>минуты</Label>
                <Input
                  type="number"
                  {...register('delay_minutes')}
                  min={0}
                  onChange={() => delayError && setDelayError(false)}
                  className="mt-2 max-w-[100px]"
                />
              </div>
            </div>
          </div>

          <Button
            type="button"
            loading={isLoading}
            onClick={handleSubmit(formSubmitHandler)}
          >
            {type === 'create' ? 'Создать' : 'Сохранить'}
          </Button>
        </form>
      </SheetContent>
    </Sheet>
  );
};
