import { FC } from 'react';
import {
  InputGroup,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/UI';
import { useGetGroupsQuery } from '@/redux/api';

type Props = {
  value: string;
  onValueChange: (val: string) => void;
};

export const GroupsSelect: FC<Props> = ({ onValueChange, value }) => {
  const { data } = useGetGroupsQuery();

  return (
    <InputGroup id="group_id" name="Группа рассылки">
      <span>
        <Select value={value} onValueChange={onValueChange}>
          <SelectTrigger>
            <SelectValue placeholder="Все пользователи" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">Все пользователи</SelectItem>
            {data?.map((g) => (
              <SelectItem key={g.id} value={g.id.toString()}>
                {g.name} {Boolean(g.users) && `(${g.users})`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </span>
    </InputGroup>
  );
};
