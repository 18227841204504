import { Link as LinkIcon } from 'lucide-react';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/UI/Tooltip';

type Props = {
  groupId: number;
};

export const GroupLink: React.FC<Props> = ({ groupId }) => {
  const initialTooltipText = 'Ссылка для попадания в группу';
  const [tooltipText, setTooltipText] = useState(initialTooltipText);
  const [open, setOpen] = useState(false);
  const clickCopyHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    copy(`${process.env.REACT_APP_BOT_TG_URL}?start=${groupId}`);
    setTooltipText('Скопировано');
    const t = setTimeout(() => {
      setTooltipText(initialTooltipText);
      clearTimeout(t);
    }, 1500);
  };

  return (
    <TooltipProvider>
      <Tooltip open={open}>
        <TooltipTrigger>
          <LinkIcon
            onClick={clickCopyHandler}
            className="text-primary"
            size={18}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </TooltipTrigger>
        <TooltipContent>{tooltipText}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
