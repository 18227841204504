import { useEffect, useState } from 'react';
import { SendingDrawer } from '@/components';
import { Button } from '@/components/UI';
import { useToast } from '@/hooks';
import { useCreateSendingMutation } from '@/redux/api';

export const CreateSending = () => {
  const { toast } = useToast();

  const [open, setOpen] = useState(false);

  const [createSending, { data, isLoading, reset }] =
    useCreateSendingMutation();

  useEffect(() => {
    if (!data) return;
    setOpen(false);
    reset();
    toast({
      title: 'Успешно выполнено',
      description: Boolean(data.sending_date)
        ? 'Рассылка успешно запланирована'
        : 'Началась рассылка по пользователям',
      variant: 'success',
    });
  }, [data, toast, reset]);

  return (
    <SendingDrawer
      open={open}
      onOpenChange={setOpen}
      action={(data) => createSending(data)}
      isLoading={isLoading}
      trigger={<Button>Создать рассылку</Button>}
      type="create"
    />
  );
};
