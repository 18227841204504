import { Label } from './Label';

type Props = {
  children: React.ReactNode;
  error?: string;
  name: string;
  id: string;
};

export const InputGroup: React.FC<Props> = ({ children, error, name, id }) => {
  return (
    <div>
      <div className="flex justify-between text-sm font-medium items-start mb-2 leading-none">
        <Label htmlFor={id}>{name}</Label>
        <span className="text-red-400">{error}</span>
      </div>
      {children}
    </div>
  );
};
