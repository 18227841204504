import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AutoSendingData, SendingData } from '@/types';

interface EditableAutoSendingData
  extends Pick<
    AutoSendingData,
    | 'message'
    | 'id'
    | 'delay_days'
    | 'delay_hours'
    | 'delay_minutes'
    | 'name'
    | 'group_id'
  > {}

interface EditableSendingData
  extends Pick<SendingData, 'message' | 'group_id' | 'id' | 'sending_date'> {}

interface InitialState {
  uploadedFilePath: string;
  editSendingDrawerOpen: boolean;
  editAutoSendingDrawerOpen: boolean;
  editableSendingData?: EditableSendingData;
  editableAutoSendingData?: EditableAutoSendingData;
}

const initialState: InitialState = {
  uploadedFilePath: '',
  editSendingDrawerOpen: false,
  editAutoSendingDrawerOpen: false,
  editableSendingData: undefined,
};

const sendingSlice = createSlice({
  initialState,
  name: 'sending',
  reducers: {
    setUploadedFilePath: (state, { payload }: PayloadAction<string>) => {
      state.uploadedFilePath = payload;
    },
    toggleEditSendingDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.editSendingDrawerOpen = payload;
      if (payload === false) state.editableSendingData = undefined;
    },
    toggleEditAutoSendingDrawer: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.editAutoSendingDrawerOpen = payload;
      if (payload === false) state.editableAutoSendingData = undefined;
    },
    setEditableSendingData: (
      state,
      { payload }: PayloadAction<EditableSendingData>,
    ) => {
      state.editableSendingData = payload;
    },
    setEditableAutoSendingData: (
      state,
      { payload }: PayloadAction<EditableAutoSendingData>,
    ) => {
      state.editableAutoSendingData = payload;
    },
  },
});
export const sendingReducer = sendingSlice.reducer;

export const {
  setUploadedFilePath,
  toggleEditSendingDrawer,
  setEditableSendingData,
  setEditableAutoSendingData,
  toggleEditAutoSendingDrawer,
} = sendingSlice.actions;
