import { EditSending } from './EditSending';
import { format } from 'date-fns';
import { SendingAccordion } from '@/components/';
import { Badge, Loader } from '@/components/UI';
import { useAppDispatch } from '@/redux';
import { useDeleteSendingMutation, useGetPlannedQuery } from '@/redux/api';
import {
  setEditableSendingData,
  setUploadedFilePath,
  toggleEditSendingDrawer,
} from '@/redux/features';
import { SendingData } from '@/types';

export const PlannedSending = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetPlannedQuery();

  const [deletePlanned, { isSuccess }] = useDeleteSendingMutation();

  const editAction = (data: SendingData) => {
    const { message, id, group_id, sending_date, file_path } = data;
    if (file_path) dispatch(setUploadedFilePath(file_path));

    dispatch(
      setEditableSendingData({
        message,
        group_id,
        id,
        sending_date,
      }),
    );

    dispatch(toggleEditSendingDrawer(true));
  };

  if (isLoading) return <Loader />;

  return data?.length ? (
    <div>
      {data.map((item) => (
        <SendingAccordion
          id={item.id}
          key={item.id}
          filePath={item.file_path}
          message={item.message}
          deleteAction={() => deletePlanned({ id: item.id })}
          deleteStatus={isSuccess}
          editAction={() => editAction(item)}
          title={
            <>
              {format(new Date(item.sending_date), 'd LLLL kk:mm')}
              {item.group && <Badge className="ml-3">{item.group.name}</Badge>}
            </>
          }
        />
      ))}
      <EditSending />
    </div>
  ) : (
    <div>Нет запланированных рассылок</div>
  );
};
