import {
  authAPI,
  autoSendingAPI,
  botAPI,
  commandsAPI,
  groupsAPI,
  sendingAPI,
  usersAPI,
} from './api';
import { botReducer, groupsReducer, sendingReducer } from './features';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

export const rootReducer = combineReducers({
  bot: botReducer,
  sending: sendingReducer,
  groups: groupsReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [botAPI.reducerPath]: botAPI.reducer,
  [commandsAPI.reducerPath]: commandsAPI.reducer,
  [sendingAPI.reducerPath]: sendingAPI.reducer,
  [autoSendingAPI.reducerPath]: autoSendingAPI.reducer,
  [groupsAPI.reducerPath]: groupsAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authAPI.middleware,
      botAPI.middleware,
      commandsAPI.middleware,
      sendingAPI.middleware,
      groupsAPI.middleware,
      usersAPI.middleware,
      autoSendingAPI.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
