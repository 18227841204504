import { useEffect } from 'react';
import { GroupFormDialog } from './GroupFormDialog';
import { useToast } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux';
import { useRenameGroupMutation } from '@/redux/api';
import { toggleEditDialog } from '@/redux/features';
import { ErrorResponse } from '@/types';

export const RenameGroup = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const { editDialogOpen, editableGroupData } = useAppSelector(
    (state) => state.groups,
  );

  const [renameGroup, { data, error, reset }] = useRenameGroupMutation();

  const onOpenChange = (bool: boolean) => {
    dispatch(toggleEditDialog(bool));
  };

  const rename = (name: string) => {
    if (!editableGroupData) return;
    const { id } = editableGroupData;
    renameGroup({ id, name });
  };

  useEffect(() => {
    if (!data) return;
    dispatch(toggleEditDialog(false));
    reset();
    toast({
      title: 'Успешно выполнено',
      description: 'Группа отредактирована',
      variant: 'success',
    });
  }, [data, reset, toast, dispatch]);

  const errorMessage = error
    ? (error as ErrorResponse)?.data.errors[0]['name']
    : undefined;

  return (
    <GroupFormDialog
      open={editDialogOpen}
      onOpenChange={onOpenChange}
      action={rename}
      type={'rename'}
      error={errorMessage}
      name={editableGroupData?.name}
    />
  );
};
