import { BiBlock } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { RiPulseLine } from 'react-icons/ri';
import { Commands } from './components/';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/UI';
import { useAppSelector } from '@/redux';
import { useGetUsersStatsQuery } from '@/redux/api';
import './Dashboard.css';

export const Dashboard = () => {
  const { data } = useGetUsersStatsQuery();
  const { sending_active } = useAppSelector((state) => state.bot);

  return (
    <>
      <div className="flex gap-5 flex-col lg:flex-row">
        <Card className="stat-item">
          <CardHeader>
            <CardTitle>Состояние</CardTitle>
          </CardHeader>
          <CardContent>
            <p>
              Бот: <span className="text-green-500">активен</span>
            </p>
            <p>
              Рассылка :{' '}
              {sending_active ? (
                <span className="text-green-500">выполняется</span>
              ) : (
                'не выполняется'
              )}
            </p>
          </CardContent>
        </Card>
        <Card className="stat-item">
          <CardHeader>
            <CardTitle>Всего пользователей</CardTitle>
          </CardHeader>
          <CardContent className="flex gap-3 items-center">
            <span className="stats-number">{data ? data.total : '--'}</span>
            <FiUsers className="stroke-gray-400" size={23} />
          </CardContent>
        </Card>
        <Card className="stat-item">
          <CardHeader>
            <CardTitle>Активных </CardTitle>
          </CardHeader>
          <CardContent className="flex gap-3 items-center">
            <span className="stats-number">
              {data ? data.active_users : '--'}
            </span>
            <RiPulseLine size={23} className="fill-gray-400" />
          </CardContent>
        </Card>
        <Card className="stat-item">
          <CardHeader>
            <CardTitle>Заблокировали бота</CardTitle>
          </CardHeader>
          <CardContent className="flex gap-3 items-center">
            <span className="stats-number">
              {data ? data.blocked_users : '--'}
            </span>
            <BiBlock size={23} className="fill-gray-400" />
          </CardContent>
        </Card>
      </div>

      <div className="content">
        <h1>Управление командами</h1>
        <div>
          <Commands />
        </div>
      </div>
    </>
  );
};
