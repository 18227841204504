import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  InputGroup,
} from '@/components/UI/';

type Props = {
  open: boolean;
  onOpenChange: (bool: boolean) => void;
  action: (name: string) => void;
  trigger?: React.ReactNode;
  error?: string;
  type: 'add' | 'rename';
  name?: string;
};

export const GroupFormDialog: FC<Props> = ({
  open,
  onOpenChange,
  action,
  trigger,
  error,
  type,
  name,
}) => {
  const {
    register,
    handleSubmit,
    formState,
    reset: resetFrom,
    setError,
    setValue,
  } = useForm<{ name: string }>();
  const { errors } = formState;

  useEffect(() => {
    if (!open) resetFrom();
  }, [resetFrom, open]);

  useEffect(() => {
    setError('name', { message: error });
  }, [error, setError]);

  useEffect(() => {
    if (name) setValue('name', name);
  }, [name, setValue]);

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogTrigger asChild>{trigger && trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {type === 'add' ? 'Добавить группу' : 'Переименовать группу'}
          </DialogTitle>
          <DialogDescription>
            {type === 'add'
              ? 'Придумайте название для группы'
              : 'Придумайте новое название для группы'}
          </DialogDescription>
        </DialogHeader>
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(({ name }) => action(name))}
        >
          <InputGroup id="name" name="Название" error={errors.name?.message}>
            <Input
              id="name"
              placeholder="Введите название"
              {...register('name', { required: 'Заполните поле' })}
            />
          </InputGroup>

          <Button className="mt-2">
            {type === 'add' ? 'Добавить' : 'Сохранить'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
