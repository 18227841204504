import { useState } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Menu } from '../Menu/Menu';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../UI/Sheet';
import './MobileMenu.css';

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className=" md:hidden flex items-center">
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <span>
            <RxHamburgerMenu size={20} />
          </span>
        </SheetTrigger>

        <SheetContent className="w-full md:max-w-[50%] lg:max-w-[40%] xl:max-w-[30%]">
          <SheetHeader>
            <SheetTitle>Меню</SheetTitle>
          </SheetHeader>
          <div className="mobile-menu">
            <Menu isMobile onClick={() => setOpen(false)} />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};
