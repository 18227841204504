import { Menu } from '../Menu/Menu';
import './TopMenu.css';

export const TopMenu = () => {
  return (
    <div className="top-menu">
      <Menu />
    </div>
  );
};
