import { FC, useEffect, useState } from 'react';
import { AutoSendingDrawer } from '@/components';
import { Button } from '@/components/UI';
import { useToast } from '@/hooks';
import { useAppDispatch } from '@/redux';
import { useCreateAutoSendingMutation } from '@/redux/api';
import { setUploadedFilePath } from '@/redux/features';

type Props = {
  group_id: number | undefined;
};

export const CreateAutoSending: FC<Props> = ({ group_id }) => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const [create, { isSuccess, reset, isLoading }] =
    useCreateAutoSendingMutation();

  useEffect(() => {
    if (!isSuccess) return;
    setOpen(false);
    dispatch(setUploadedFilePath(''));
    reset();
    toast({
      title: 'Успешно выполнено',
      description: 'Авторассылка успешно добавлена',
      variant: 'success',
    });
  }, [isSuccess, dispatch, toast, reset]);

  return (
    <AutoSendingDrawer
      isLoading={isLoading}
      action={create}
      open={open}
      onOpenChange={setOpen}
      type="create"
      group_id={group_id!}
      trigger={<Button>Создать авторассылку</Button>}
    />
  );
};
