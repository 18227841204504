type Props = {
  text?: string;
};

export const EmptyData: React.FC<Props> = ({ text }) => {
  return (
    <div className='flex items-center justify-center w-full'>
      <span className='text-lg italic text-gray-500'>
        {text ? text : 'Данных нет'}
      </span>
    </div>
  );
};
