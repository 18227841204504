import { useEffect } from 'react';
import { SendingDrawer } from '@/components';
import { useToast } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux';
import { useUpdateSendingMutation } from '@/redux/api';
import { toggleEditSendingDrawer } from '@/redux/features';
import { CreateSendingData } from '@/types';

export const EditSending = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const { editSendingDrawerOpen, editableSendingData } = useAppSelector(
    (state) => state.sending,
  );

  const update = (data: CreateSendingData) => {
    if (editableSendingData) {
      updateSending({
        ...data,
        id: editableSendingData.id,
        sending_date: data.sending_date!,
      });
    }
  };

  const [updateSending, { data, isLoading, reset }] =
    useUpdateSendingMutation();

  useEffect(() => {
    if (!data) return;
    dispatch(toggleEditSendingDrawer(false));
    reset();
    toast({
      title: 'Успешно выполнено',
      description: 'Рассылка успешно отредактирована',
      variant: 'success',
    });
  }, [data, toast, reset, dispatch]);

  return (
    <SendingDrawer
      open={editSendingDrawerOpen}
      onOpenChange={(bool) => dispatch(toggleEditSendingDrawer(bool))}
      action={update}
      isLoading={isLoading}
      type="edit"
      message={editableSendingData?.message}
      group_id={editableSendingData?.group_id}
      sending_date={editableSendingData?.sending_date}
    />
  );
};
