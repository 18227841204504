import { Outlet } from 'react-router-dom';
import { Header } from '@/components';
import { Toaster } from '@/components/UI';
import { useGetBotStatusQuery } from '@/redux/api';

export const PageLayout = () => {
  useGetBotStatusQuery();

  return (
    <>
      <Header />
      <main className="container mt-10">
        <Outlet />
        <Toaster />
      </main>
    </>
  );
};
