import { routing } from '@/configs';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/api`,
  prepareHeaders: (headers) => {
    const access_token = localStorage.getItem('at');
    if (access_token) headers.set('authorization', `Bearer ${access_token}`);
    return headers;
  },
});

const customFetch: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('at');
    window.location.href = routing.signin;
  }

  return result;
};

export default customFetch;
