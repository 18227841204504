import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  InputGroup,
  Textarea,
} from '@/components/UI/';
import { useAddCommandMutation } from '@/redux/api';
import { BotCommand, ErrorResponse } from '@/types';

export const AddCommandDialog = () => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
    reset: resetFrom,
  } = useForm<BotCommand>();
  const { errors } = formState;

  const [isOpen, setOpen] = useState(false);

  const [addCommand, { data, error, reset }] = useAddCommandMutation();

  useEffect(() => {
    if (!data) return;
    setOpen(false);
    resetFrom();
    reset();
  }, [data, resetFrom, reset]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as ErrorResponse;

    if (data.errors) {
      data.errors.forEach((e) => {
        const key = Object.keys(e)[0];
        setError('command', { message: e[key] });
      });
    }
  }, [error, setError]);

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogTrigger asChild>
        <Button className="w-full sm:w-fit">Добавить команду</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Добавить команду</DialogTitle>
          <DialogDescription>Заполните все обязательные поля</DialogDescription>
        </DialogHeader>
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit((data) => {
            const command = data.command.startsWith('/')
              ? data.command
              : `/${data.command}`;
            addCommand({ ...data, command });
          })}
        >
          <InputGroup
            id="command"
            name="команда"
            error={errors.command?.message}
          >
            <Input
              id="command"
              placeholder="например /start"
              {...register('command', { required: 'Заполните поле' })}
            />
          </InputGroup>
          <InputGroup
            id="description"
            name="короткое описание"
            error={errors.description?.message}
          >
            <Input
              id="description"
              placeholder="короткое описание"
              {...register('description', { required: 'Заполните поле' })}
            />
          </InputGroup>
          <InputGroup id="text" name="ответ" error={errors.text?.message}>
            <Textarea
              id="text"
              placeholder="какой то ответ"
              {...register('text', { required: 'Заполните поле' })}
            />
          </InputGroup>

          <Button className="mt-2">Добавить</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
