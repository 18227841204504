import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  HiddenInput,
  Input,
  InputGroup,
} from '@/components/UI';
import { routing } from '@/configs';
import { useSigninMutation } from '@/redux/api';
import { SignInCredentials } from '@/types';
import './Signin.css';

export const Signin = () => {
  const { register, handleSubmit, formState, setError } =
    useForm<SignInCredentials>();
  const { errors } = formState;

  const [signin, { data, error }] = useSigninMutation();

  useEffect(() => {
    if (!data) return;
    localStorage.setItem('at', data.token);
    window.location.href = routing.dashboard;
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const errorMsg = { message: 'Неверный логин или пароль' };
    setError('login', errorMsg);
    setError('password', errorMsg);
  }, [error, setError]);

  return (
    <div className="signin-page">
      <Card>
        <CardHeader className="text-center">
          <CardTitle>Вход</CardTitle>
          <CardDescription>Войти в панель управления</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit((data) => signin(data))}>
            <InputGroup id="login" name="логин" error={errors.login?.message}>
              <Input
                id="login"
                placeholder="введите логин"
                {...register('login', { required: 'Заполните поле' })}
              />
            </InputGroup>
            <InputGroup
              id="password"
              name="пароль"
              error={errors.password?.message}
            >
              <HiddenInput
                placeholder="введите пароль"
                id="password"
                {...register('password', { required: 'Заполните поле' })}
              />
            </InputGroup>
            <Button className="mt-2">Войти</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
