import { AddGroup } from './components/AddGroup';
import { GroupCard } from './components/GroupCard';
import { RenameGroup } from './components/RenameGroup';
import { EmptyData, Loader } from '@/components/UI';
import { cn } from '@/lib/utils';
import { useGetGroupsQuery } from '@/redux/api';
import './Groups.css';

export const Groups = () => {
  const { data, isLoading } = useGetGroupsQuery();

  return (
    <>
      <div>
        <AddGroup />
      </div>
      <div className="content">
        <h1>Группы пользователей</h1>
        <div
          className={cn(
            'groups',
            (isLoading || !data?.length) && 'justify-center items-center',
          )}
        >
          {isLoading ? (
            <Loader />
          ) : data?.length ? (
            data.map((group) => <GroupCard group={group} key={group.id} />)
          ) : (
            <EmptyData text="групп пока нет" />
          )}
        </div>
      </div>
      {data?.length && <RenameGroup />}
    </>
  );
};
