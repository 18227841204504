import { createApi } from '@reduxjs/toolkit/dist/query/react';
import customFetch from './customFetch';
import { BotCommand, getDataAndCount } from '@/types';

export const commandsAPI = createApi({
  reducerPath: 'commandsAPI',
  baseQuery: customFetch,

  tagTypes: ['Commands'],
  endpoints: (builder) => ({
    getCommands: builder.query<getDataAndCount<BotCommand>, void>({
      query: () => ({
        url: '/commands',
        method: 'GET',
      }),
      providesTags: (_) => ['Commands'],
    }),
    addCommand: builder.mutation<BotCommand, BotCommand>({
      query: (body) => ({
        url: '/commands',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Commands'],
    }),
    deleteCommand: builder.mutation<{ status: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/commands/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Commands'],
    }),
  }),
});

export const {
  useGetCommandsQuery,
  useAddCommandMutation,
  useDeleteCommandMutation,
} = commandsAPI;
