import { FC, useEffect, useState } from 'react';
import { RiDeleteBin7Line } from 'react-icons/ri';
import {
  Button,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/UI/';
import { cn } from '@/lib/utils';

type Props = {
  text: string;
  deleteAction: () => void;
  deleteStatus: boolean;
  absolute?: boolean;
};

export const ConfirmDeleteAction: FC<Props> = ({
  text,
  deleteAction,
  deleteStatus,
  absolute,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (deleteStatus) setOpen(false);
  }, [deleteStatus]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <span
          onClick={(e) => e.stopPropagation()}
          className={cn(absolute && 'absolute right-0')}
        >
          <RiDeleteBin7Line
            size={21}
            className="fill-destructive cursor-pointer"
          />
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <Label>{text}</Label>
        <div className="flex gap-2 mt-2">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
            size="sm"
            variant="secondary"
            className="w-full"
          >
            отменить
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              deleteAction();
            }}
            size="sm"
            variant="destructive"
            className="w-full"
          >
            удалить
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
