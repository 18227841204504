import { FC } from 'react';
import { ConfirmDeleteAction } from '@/components/';
import { Button, InputGroup, Label } from '@/components/UI';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/UI';

const domain = process.env.REACT_APP_API_URL;

type Props = {
  id: number;
  filePath: string | null;
  message: string;
  editAction?: () => void;
  deleteAction: () => void;
  deleteStatus: boolean;
  title: React.ReactNode;
};

export const SendingAccordion: FC<Props> = ({
  id,
  filePath,
  message,
  title,
  deleteAction,
  deleteStatus,
  editAction,
}) => {
  return (
    <Accordion
      key={id}
      type="single"
      collapsible
      className="w-full border rounded-lg mt-5 px-5"
    >
      <AccordionItem className="border-b-0" value={id.toString()}>
        <AccordionTrigger>
          <div>{title}</div>
          <ConfirmDeleteAction
            text="Удалить рассылку?"
            deleteStatus={deleteStatus}
            deleteAction={deleteAction}
            absolute
          />
        </AccordionTrigger>

        <AccordionContent>
          <div className="flex gap-5 flex-col lg:flex-row">
            <div className="md:w-1/2">
              <InputGroup id="message" name="Сообщение">
                <div>{message}</div>
              </InputGroup>
            </div>
            {filePath && (
              <div className="md:w-1/2">
                <Label className="flex mb-2" htmlFor="file">
                  Медиа
                </Label>
                {filePath.includes('.mp4') ? (
                  <div className="rounded-lg opacity-50 h-[198px] overflow-hidden">
                    <video src={domain + filePath}></video>
                  </div>
                ) : (
                  <div
                    className="h-[200px] rounded-lg opacity-50"
                    style={{
                      backgroundImage: `url(${domain + filePath})`,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                )}
              </div>
            )}
          </div>
          <div className="flex justify-end mt-5">
            <Button onClick={editAction} size="sm">
              редактировать
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
