import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Group } from '@/types';

interface InitialState {
  editDialogOpen: boolean;
  editableGroupData?: Pick<Group, 'id' | 'name'>;
}

const initialState: InitialState = {
  editDialogOpen: false,
};

const groupsSlice = createSlice({
  initialState,
  name: 'groups',
  reducers: {
    toggleEditDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.editDialogOpen = payload;
      if (payload === false) {
        state.editableGroupData = undefined;
      }
    },
    setEditableGroupData: (
      state,
      { payload }: PayloadAction<Pick<Group, 'id' | 'name'>>,
    ) => {
      state.editableGroupData = payload;
    },
  },
});
export const groupsReducer = groupsSlice.reducer;

export const { toggleEditDialog, setEditableGroupData } = groupsSlice.actions;
