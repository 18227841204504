import { ReactRouterProvider } from './providers';
import { ReduxProvider } from './providers/ReduxProvider';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';
setDefaultOptions({ locale: ru });

function App() {
  return (
    <ReduxProvider>
      <ReactRouterProvider />
    </ReduxProvider>
  );
}

export default App;
