import { createApi } from '@reduxjs/toolkit/dist/query/react';
import customFetch from './customFetch';
import { BotUsersStats } from '@/types';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: customFetch,

  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsersStats: builder.query<BotUsersStats, void>({
      query: () => ({
        url: '/users/get-users-stats',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUsersStatsQuery } = usersAPI;
