import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routing } from '@/configs';

type Props = {
  isMobile?: boolean;
  onClick?: () => void;
};

export const Menu: FC<Props> = ({ isMobile, onClick }) => {
  const clickHandler = () => {
    if (isMobile && onClick) onClick();
  };

  return (
    <ul className="menu">
      <li>
        <Link onClick={clickHandler} to={routing.dashboard}>
          Дашбоард
        </Link>
      </li>
      <li>
        <Link onClick={clickHandler} to={routing.sending}>
          Рассылка
        </Link>
      </li>
      <li>
        <Link onClick={clickHandler} to={routing.groups}>
          Группы
        </Link>
      </li>
    </ul>
  );
};
